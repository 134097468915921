import Header from '../components/Header.jsx'
import Contact from '../components/Contact.jsx'
import {Box, Grid, Typography, Stack, Paper} from '@mui/material'
import {Link} from 'react-router-dom'
import FRLogo from '../assets/fill-rite-logo.png'
import OPWLogo from '../assets/opw-logo.png'
import FLogo from '../assets/franklin-logo.png'
import WLogo from '../assets/wayne-fueling-logo.png'
import VLogo from '../assets/verifone-logo.png'
import AHLogo from '../assets/ablehose-logo.png'
import XSLogo from '../assets/xerxes-logo.png'
import PumpTrailer from '../assets/pump-trailer.jpg'

const Products = () => {
    return (
        <>
            <Header/>
            <Paper sx={{backgroundColor:'#dedee0', height:'100vh'}}>
                <Stack spacing={5} sx={{display:'flex',alignItems:'center',justifyContent:'center', pt:'12.5vh', pl:5, pr:5}}>
                    <Box sx={{height:'50vh'}}>
                        <Typography variant='h2'>MPT Products</Typography>
                        <Box>
                            <Typography variant='h6'>We are the #1 most trusted retailer in the region and we are proud of our</Typography>
                            <Typography variant='h6'>distributors. Fill-Rite®, OPW®, Franklin Fueling Systems®, Wayne</Typography>
                            <Typography variant='h6'>Fueling Systems®, and Verifone®, are some of our listed distributions which</Typography>
                            <Typography variant='h6'>our certified technicians are prepared to service and are on call 24/7.</Typography>
                        </Box>
                    </Box>
                </Stack>
            </Paper>
            <Paper sx={{m:'auto', backgroundImage:`url(${PumpTrailer})`, backgroundAttachment: 'fixed', backgroundSize:'cover'}}>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="https://www.fillrite.com/">
                            <Box component="img"
                            alt="FillRite DEF"
                            src={FRLogo} sx={{width:'100%'}}/>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="https://www.opwglobal.com/">
                            <Box component="img"
                            alt="OPW Logo"
                            src={OPWLogo} sx={{width:'100%'}}/>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="https://verifone.com/en/us">
                            <Box component="img"
                            alt="Verifone Logo"
                            src={VLogo} sx={{width:'100%'}}/>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="https://www.doverfuelingsolutions.com/">
                            <Box component="img"
                            alt="Wayne Logo"
                            src={WLogo} sx={{width:'100%'}}/>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="https://www.franklinfueling.com">
                            <Box component="img"
                            alt="Franklin logo"
                            src={FLogo} sx={{width:'100%'}}/>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="https://www.ablehose.com">
                            <Box component="img"
                            alt="Ablehose logo"
                            src={AHLogo} sx={{width:'100%'}}/>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{m:'auto'}}>
                        <Link to="https://www.xerxes.com">
                            <Box component="img"
                            alt="Xerxes logo"
                            src={XSLogo} sx={{width:'100%'}}/>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{backgroundColor:'#dedee0', height:'20vh'}}/>
            <Contact/>
        </>
    )
}

export default Products;