import Header from '../components/Header.jsx'
import Slides from '../components/Slides.jsx'
import Contact from '../components/Contact.jsx'
import {Paper, Grid, Stack, Typography, ImageList, ImageListItem, Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import Canopy from '../assets/canopy-night.jpg'
import SubPump from '../assets/tanks-submersables.jpg'
import UndergroundEmpty from '../assets/underground-tank-2.jpg'
import PV100 from '../assets/pv100.jpg'

const Home = () => {
    const navigate = useNavigate()
    return(
        <>
            <Paper sx={{position:'absolute', backgroundColor:'#dedee0'}}>
                <Header/>
                <Slides/>
                <Grid container direction="column" spacing={5} sx={{width:'75%', m:'auto'}}>
                    <Grid container spacing={5}>
                        <Grid item sm={6} xs={12}>
                            <Stack>
                                <Grid container sx={{display:'flex', alignItems:"top", justifyContent:'center'}}>
                                    <Typography variant="h3">Products</Typography>
                                </Grid>
                                <Stack sx={{display:'flex', alignItems:'center', justifyContent:"left"}}>
                                    <Typography variant={'h6'}>Here at Midwest Pump and Tank we understand that you don't want to wait
                                        for products to arrive at your door. That's why we are distributors for 7+ of
                                        the top petrol companies. Stop in our store or call to see if we can meet your
                                        petroleum needs.</Typography>
                                    <Button onClick={()=>navigate('/products')}>Learn More</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12} sx={{display:'flex', alignItems:"center", justifyContent:'left'}}>
                            <ImageList>
                                <ImageListItem>
                                    <img src={Canopy} alt="Canopy contracted and built by MPT"/>
                                </ImageListItem>
                                <ImageListItem>
                                    <img src={PV100} alt="PV100 Programmed by MPT"/>
                                </ImageListItem>
                            </ImageList>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item sm={6} xs={12}>
                            <Stack>
                                <Grid container sx={{display:'flex', alignItems:"top", justifyContent:'center'}}>
                                    <Typography variant="h3">Services</Typography>
                                </Grid>
                                <Stack sx={{display:'flex', alignItems:'center', justifyContent:"right"}}>
                                    <Typography variant={'h6'}>Midwest Pump and Tank services all petrol equipment.
                                        Whatever the equipment may be, our certified technicians are more than
                                        capable of diagnosing and repairing equipment from your everyday pumps and
                                        nozzles to large truck petroleum equipment.</Typography>
                                    <Button onClick={()=>navigate('/services')}>Learn More</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12} sx={{display:'flex', alignItems:"center", justifyContent:'left'}}>
                            <ImageList>
                                <ImageListItem>
                                    <img src={UndergroundEmpty} alt="Underground tank when empty"/>
                                </ImageListItem>
                                <ImageListItem>
                                    <img src={SubPump} alt="Set of Submersables Bought and Installed"/>
                                </ImageListItem>
                            </ImageList>
                        </Grid>
                    </Grid>
                </Grid>
                <Contact/>
            </Paper>
        </>
        )
}

export default Home;