import Header from '../components/Header'
import Contact from '../components/Contact.jsx'
import {Typography, Stack, Grid, Paper, ImageList, ImageListItem} from '@mui/material'
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FieldService from '../assets/field-services-1.jpg'
import ServiceBackground from '../assets/service-background.jpg'

const Services = () => {

    return(
        <>
            <Header/>
            <Paper sx={{m:'auto', width:'100%', backgroundImage:`url(${ServiceBackground})`, backgroundAttachment: 'fixed', backgroundSize:'cover'}}>
                <Stack sx={{m:"auto", width:'90%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Grid container direction={'column'} spacing={5} sx={{display:'flex', alignItems:'center', justifyContent:'center', p:5}}>
                        <Grid item xs={12}>
                            <Typography variant={"h2"}>Services</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{pb:5}}>
                            <Typography variant={'h5'}>Midwest Pump & Tank offers a wide variety of services from new petroleum
                            builds to bulk truck servicing and equipment.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item sm={12} md={4} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <ImageList>
                                <ImageListItem sx={{width:'200%'}}>
                                    <img src={FieldService} alt="Canopy contracted and built by MPT"/>
                                </ImageListItem>
                            </ImageList>
                        </Grid>
                        <Grid item sm={12} md={8} sx={{pb:5}}>
                            <Stack sx={{display:'flex', pt:5}}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="h4">Tech Support</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography> Technicians are on call 24/7 ready to assist you. Contact us at: 
                                             <a href="tel:605-225-0577">(605) 225-0577</a> and walk us through the technical issue you're facing. We
                                            will assess your situation and walk you through possible solutions.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="h4">Sales</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>We carry a variety of products from nozzles and swivels to pumps of
                                            all kind. Stop by the store to check out our entire inventory.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="h4">Installation</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography> We install a variety of systems systems including pumps, fuel management
                                            systems, dispensers, and bulk truck hoses.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="h4">Warranty</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography> All of the product we install are backed by the manufacturers warranty
                                            and can be replaced with service</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="h4">Upgrades</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography> The products we install require scheduled upgrades. Whether it is
                                            technical upgrades or new components to your fueling systems, we will remind
                                            and assist you with your upgrades</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="h4">Full-Builds</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography> Certified technicians with your interests in mind and years of
                                            experience will work with you to develop your perfect build.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </Paper>
            <Contact/>
    </>)
}

export default Services;
