import {Stack, Typography, Grid, Paper} from '@mui/material'
import {Link} from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Contact = () => {
    const theme = createTheme({
      typography: {
        allVariants: {
          color: '#ffffff',
        },
      },
    });
    return (<>
        <Paper elevation={3} sx={{backgroundColor:'#0c15b0'}}>
            <Grid container sx={{mb:0}}>
                <ThemeProvider theme={theme}>
                    <Grid item xs={12} sm={4}>
                        <Stack sx={{display:'flex', alignItems:'center', justifyContent:"center"}}>
                            <Typography variant="h3">Quick Links</Typography>
                            <Link to="/Products">
                                <Typography>Products</Typography>
                            </Link>
                            <Link to="/DEF">
                                <Typography>DEF Products</Typography>
                            </Link>
                            <Link to="/Services">
                                <Typography>Services</Typography>
                            </Link>
                            <Link to="/About">
                                <Typography>About Us</Typography>
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Stack sx={{display:'flex', alignItems:'center', justifyContent:"center"}}>
                            <Typography variant="h3">Visit Us</Typography>
                            <Typography>770 S Highway 281</Typography>
                            <Typography>Frontage Rd</Typography>
                            <Typography>Aberdeen, SD 57401</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Stack sx={{display:'flex', alignItems:'center', justifyContent:"center"}}>
                            <Typography variant="h3">Support</Typography>
                            <Typography><a href="tel:605-225-0577" style={{ color: 'white' }}>(605) 225-0577</a></Typography>
                            <Typography><a href="tel:800-874-9231" style={{ color: 'white' }}>(800) 874-9231</a></Typography>
                            <Typography>Email: <a href='mailto:mpt@midwestpump.com' style={{color:'white'}}>MPT@midwestpump.com</a></Typography>
                            <Typography>Fax: (605) 225-0516</Typography>
	    		</Stack>
                    </Grid>
                </ThemeProvider>
            </Grid>
        </Paper>
    </>)
}
export default Contact;
