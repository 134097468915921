import Header from '../components/Header.jsx'
import Contact from '../components/Contact.jsx'
import { Table, TableBody, TableCell, TableHead, TableRow,
    Grid, Typography, Stack, ImageList, ImageListItem} from '@mui/material';
import MPTStore from '../assets/MPT-store.jpg'


const About = () => {
    const hours = [{'day': 'Sunday', 'hours': 'Closed'},
    {'day': 'Monday', 'hours': '8am-5pm'},
    {'day': 'Tuesday', 'hours': '8am-5pm'},
    {'day': 'Wednesday', 'hours': '8am-5pm'},
    {'day': 'Thursday', 'hours': '8am-5pm'},
    {'day': 'Friday', 'hours': '8am-5pm'},
    {'day': 'Saturday', 'hours': 'Closed'}]
    const currentDay = new Date().getDay()
    return(<>
    <Header/>
    <Grid container sx={{ml:5,mr:5}}>
        <Grid item sm={12} md={6} sx={{display:'flex', alignItems:'center',justifyContent:'center', textAlign:'center'}}>
            <Stack sx={{width:'80%'}}>
                <Typography variant="h3">About Us</Typography>
                <Typography variant={'h5'}>Established 1994, Midwest pump and tank has been providing equipment and servicing
                    commercial, farm, and residential fueling stations at all levels. </Typography>
                <Typography variant={'h5'}>With technicians available 24/7, we are prepared to fully service fueling stations all
                    over the region.</Typography>
                <ImageList sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                    <ImageListItem>
                        <img src={MPTStore} alt="MPT Storefront"/>
                    </ImageListItem>
                </ImageList>
            </Stack>
        </Grid>
        <Grid item sm={12} md={6} sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
            <Table sx={{maxWidth:'500px'}}>
                <TableHead>
                    <TableRow>
                        <TableCell><b>Day</b></TableCell>
                        <TableCell><b>Open Hours</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                  {hours.map((day, index) => (
                    <TableRow sx={currentDay === index ? { backgroundColor: '#e0f7fa' } : {}}>
                      <TableCell>{day.day}</TableCell>
                      <TableCell>{day.hours}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
            </Table>
        </Grid>
      </Grid>
      <Contact/>
  </>)
}
export default About;