import {Card, CardMedia, Box} from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import Storefront from '../assets/storefront.jpg'
import MPTTrailer from '../assets/MPT-trailer.jpg'

const Slides = () => {
    return(<>
        <Box sx={{width:'75%', m:'auto'}}>
            <Carousel>
                {/* TODO: Slide ii: h1:#1Trusted, certs*greyscale */}
                {[Storefront,MPTTrailer].map((src)=>{
                    return (<Card>
                        <CardMedia component='img' image={src}/>
                        </Card>)
                    })}
            </Carousel>
        </Box>
    </>)}
export default Slides;