import {Typography, AppBar, Toolbar, Button, Grid, Card,
    CardMedia, Paper, Menu, MenuItem} from '@mui/material'
import Stack from '@mui/material/Stack'
import {Link, useNavigate} from 'react-router-dom'
import { useState } from 'react'
import MPTLogo from '../assets/midwestpumplogo.jpg'


const Header = () => {
    const navigate = useNavigate()
    const [anchor, setAnchor] = useState(null)
    const open = Boolean(anchor)
    const setAnchorEvent = (event) => {
        setAnchor(event.currentTarget)
    }
    const handleClose = () => {
        setAnchor(null)
    }
    const equipmentNav = () => {
        navigate('/Products')
    }
    const defNav = () => {
        navigate('/Def')
    }
    const serviceNav = () => {
        navigate('/Services')
    }
    return(
    <>
        <Stack sx={{mb:1}}>
            <AppBar position="static" elevation={0} sx={{backgroundColor:'#0c15b0', width:'100%'}}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={2}>
                            <Card>
                                <CardMedia component="img" src={MPTLogo}/>
                            </Card>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container sx={{display:'flex', justifyContent:'flex-end'}}>
                                <Grid item xs='auto'>
                                    <Link to="/">
                                        <Button>
                                            <Typography color="white">Home</Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item xs='auto'>
                                        <Button onClick={setAnchorEvent}>
                                            <Typography color="white">Products</Typography>
                                        </Button>
                                    {/*</Link>*/}
                                </Grid>
                                <Grid item xs='auto'>
                                    <Link to="/About">
                                        <Button>
                                            <Typography color="white">About Us</Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Paper sx={{height:'10px', backgroundColor:'#fc0000'}}/>
        </Stack>
        <Menu
            anchorEl={anchor}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={equipmentNav}>Pumps/Equipment</MenuItem>
            <MenuItem onClick={defNav}>DEF</MenuItem>
            <MenuItem onClick={serviceNav}>Services</MenuItem>
        </Menu>
    </>)
}
export default Header;