import Header from '../components/Header.jsx'
import Contact from '../components/Contact.jsx'
import {Grid,Stack,Typography,Box} from '@mui/material'
import DefPump from '../assets/def-pump.jpg'

const Def = () => {
     return (<>
         <Header/>
         <Grid container sx={{m:"auto", width:"90vw"}}>
             <Grid item lg={12}>
                 <Grid container spacing={10} sx={{display:'flex', justifyContent:'center', alignItems:'center', mb:5}}>
                     <Grid item sm={12} md={6} sx={{display:'flex', justifyContent:'center', alignItems:'right'}}>
                        <Box component="img" alt="Horizontal DEF Pump" src={DefPump} sx={{width:'40%'}}/>
                     </Grid>
                     <Grid item sm={12} md={6} sx={{display:'flex', justifyContent:'center', alignItems:'left'}}>
                        <Stack spacing={0} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <Typography variant={'h3'}>DEF Units</Typography>
                            <Typography variant={'h5'}>DEF units may be standalone or fully installed systems. These units provide customers
                                 with a safe and efficient way to meet their DEF needs. These units significantly reduces the
                                 carbon footprint of it's usage. Call us for a quote today at <a href="tel:605-225-0577">(605) 225-0577</a> so one of our licenced
                                 technicians may recommend the most effective system.</Typography>
                        </Stack>
                     </Grid>
                 </Grid>
             </Grid>
         </Grid>
         <Contact/>
     </>)
}

export default Def;
